export function cleanSearchHightlight(highlight?: string) {
  if (!highlight) return undefined;
  let text = highlight;
  // Update the highlights from Knowledge
  if (
    highlight &&
    highlight.indexOf(
      "<span class='result_token' style='background-color:#ffc8c4;'>",
    ) > -1 &&
    highlight.indexOf("</span>") > -1
  ) {
    text = highlight
      .replaceAll(
        "<span class='result_token' style='background-color:#ffc8c4;'>",
        "<mark>",
      )
      .replaceAll("</span>", "</mark>");
  }

  return text;
}
